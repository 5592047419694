<template>
  <div>
    <div class="c-searchMenu__wrap">
      <div v-if="isDisplaySearch" class="c-searchMenu">
        <div class="l-flex">
          <div v-if="shorten().length === 0">
            <div class="title icon">条件なし</div>
          </div>
          <template v-else v-for="(s, index) in shorten()">
            <div class="title" :class="[{ icon: index === 0 }]" :key="'shorten-' + index">
              {{ s.key }}<div class="data"> : {{ s.value }}</div>
            </div>
          </template>
        </div>
        <div class="l-flex">
          <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
        </div>
      </div>
    </div>
    <div class="l-full-white l-area">
      <div class="l-box l-flex">
        <h3 class="c-lead-s-black icon">検索</h3>
        <div class="c-tabs l-area-l">
          <div
            class="c-tabs__each"
            :class="{ active: !isSearchDetail }"
            @click="isSearchDetail = false; initSearch(false);"
          >簡易</div>
          <div
            class="c-tabs__each"
            :class="{ active: isSearchDetail }"
            @click="isSearchDetail = true; initSearch(false);"
          >詳細</div>
        </div>
      </div>
      <div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols center search-wrap">
            <div>
              <span class="text-label">協議書管理番号</span>
              <InputText
                :value.sync="searchParam.priorConsultationNo"
                inputType="text"
                placeholder="協議書管理番号を入力"
                :validation="validateMaxLen"
                :validateArg="100"
              >
              </InputText>

            </div>
            <div>
              <span class="text-label">搬入先自治体</span>
              <InputText
                :value.sync="searchParam.targetMunicipalityName"
                inputType="text"
                placeholder="搬入先自治体を入力"
                :validation="validateMaxLen"
                :validateArg="100"
              >
              </InputText>
            </div>
            <div>
              <span class="text-label">排出事業者</span>
              <Selectbox
                  class="mid"
                  :value.sync="searchParam.generateCompanyId"
                  :validation="validateJustBlank"
              >
                <option value="">すべて</option>
                <option
                    v-for="(opt, index) in generateCompanyList"
                    :key="`gc-opt-${index}`"
                    :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols center search-wrap">
            <div>
              <span class="text-label">廃棄物種類</span>
              <Selectbox
                class="mid"
                :value.sync="searchParam.jwnetItemTypeId"
                :validation="validateJustBlank"
              >
                <option value="">すべて</option>
                <option
                  v-for="(opt, index) in jwnetItemType"
                  :key="`gc-opt-${index}`"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
            <div>
              <span class="text-label">ルート名</span>
              <InputText
                  :value.sync="searchParam.routeName"
                  inputType="text"
                  placeholder="ルート名を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
              >
              </InputText>
            </div>
            <div>
              <span class="text-label">運搬事業者</span>
                  <v-autocomplete ref="collect_autocomplete" @change="s => {onChange(s, 'collect_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectGeneratorCollectList" :min-len="0" :wait="300" @update-items='updateGeneratorCollectList' v-model="selectedGeneratorCollect" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
            </div>
            <div>
              <span class="text-label">処分事業者</span>
                  <v-autocomplete ref="disposal_autocomplete" @change="s => {onChange(s, 'disposal_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectGeneratorDisposalList" :min-len="0" :wait="300" @update-items='updateGeneratorDisposalList' v-model="selectedGeneratorDisposal" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
            </div>
          </div>
        </div>

        <!-- 詳細のときだけ表示 -->
        <template v-if="isSearchDetail">
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">協議書期間開始年月</span>
                <div class="c-inputWrap__items__cols search-wrap">
                  <InputDate
                    :value.sync="searchParam.priorConsultationStartDateFrom"
                    placeholder="0000/00/00"
                  />
                  <span>〜</span>
                  <InputDate
                    :value.sync="searchParam.priorConsultationStartDateTo"
                    placeholder="0000/00/00"
                  />
                </div>
              </div>
              <div>
                <span class="text-label">協議書期間終了年月</span>
                <div class="c-inputWrap__items__cols search-wrap">
                  <InputDate
                    :value.sync="searchParam.priorConsultationEndDateFrom"
                    placeholder="0000/00/00"
                  />
                  <span>〜</span>
                  <InputDate
                    :value.sync="searchParam.priorConsultationEndDateTo"
                    placeholder="0000/00/00"
                  />
                </div>
              </div>
              <div>
                <span class="text-label"></span>
                <div class="l-flex wrap full">
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                          id="is-including-invalid"
                          type="checkbox"
                          v-model="searchParam.isOveringAlert"
                          :value="true"
                      />
                      <label class="c-checkbox__label" for="is-including-invalid">
                        <span class="c-checkbox__box"></span>
                        警告基準値を超過
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="l-container">
      <div class="l-btns l-block-lg">
        <div class="c-btn secondary small" @click="resetSearchHandler">
          条件をリセット
        </div>
        <div class="c-btn primary small" @click="searchHandler">検索</div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Selectbox from "@/components/parts/input/Selectbox";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";

export default {
  components: {
    InputText,
    InputDate,
    Selectbox,
  },
  mixins: [validation, callApi, selectionLocalStorage],
  data() {
    return {
      selectedGeneratorCollect : '', //選択した運搬事業者
      selectedGeneratorDisposal: '', //選択した処分事業者
      autoSelect: false,
      isDisplaySearch: false,
      isSearchDetail: false,
      selectTemplate: SelectItemTemplate,
      // 検索v-model
      searchParam: {
        priorConsultationNo:"",
        targetMunicipalityName: "",
        generateCompanyId: "",
        jwnetItemTypeId: "",
        routeName: "",
        collectCompanyId: "",
        disposalCompanyId: "",
        priorConsultationStartDateFrom: "",
        priorConsultationStartDateTo: "",
        priorConsultationEndDateFrom: "",
        priorConsultationEndDateTo: "",
        isOveringAlert: "",
      },
    };
  },

  methods: {
    onChange(queryString, ref) {
      this.$nextTick(() => {
          this.$refs[ref].searchText = queryString;
      });
    },
    initSearch(isAll = true) {
      this.selectedGeneratorCollect = isAll ? "" : this.selectedGeneratorCollect
      this.selectedGeneratorDisposal = isAll ? "" : this.selectedGeneratorDisposal
      this.searchParam = {
        priorConsultationNo: isAll ? "" : this.searchParam.priorConsultationNo,
        targetMunicipalityName: isAll ? "" : this.searchParam.targetMunicipalityName,
        generateCompanyId: isAll ? "" : this.searchParam.generateCompanyId,
        jwnetItemTypeId: isAll ? "" : this.searchParam.jwnetItemTypeId,
        routeName: isAll ? "" : this.searchParam.routeName,
        collectCompanyId: isAll ? "" : this.searchParam.collectCompanyId,
        disposalCompanyId: isAll ? "" : this.searchParam.disposalCompanyId,
        priorConsultationStartDateFrom: "",
        priorConsultationStartDateTo: "",
        priorConsultationEndDateFrom: "",
        priorConsultationEndDateTo: "",
        isOveringAlert: "",
      };
    },

    resetSearchHandler() {
      this.initSearch();
    },

    searchHandler() {
      this.$emit("search", this.searchParam);
    },

    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    shorten() {
      const result = [];
      const prev = this.searchParam;
      const isSearchDetail = this.isSearchDetail;

      if (prev.priorConsultationNo) result.push({ key: "協議書番号", value: prev.priorConsultationNo });
      if (prev.targetMunicipalityName) result.push({ key: "自治体", value: prev.targetMunicipalityName });
      if (prev.generateCompanyId) result.push({ key: "排出事業者", value: this.generateCompanyList.filter(gc => gc.id === prev.generateCompanyId)[0].name });
      if (prev.jwnetItemTypeId) result.push({ key: "廃棄物種類", value: this.jwnetItemType.filter(jit => jit.id === prev.jwnetItemTypeId)[0].name });
      if (prev.routeName) result.push({ key: "ルート名", value: prev.routeName });
      if (prev.collectCompanyId) result.push({ key: "運搬事業者", value: this.generatorCollectList.filter(jit => jit.id === prev.collectCompanyId)[0].name });
      if (prev.disposalCompanyId) result.push({ key: "処分事業者", value: this.generatorDisposalList.filter(jit => jit.id === prev.disposalCompanyId)[0].name });
      if (isSearchDetail && (prev.priorConsultationStartDateFrom || prev.priorConsultationStartDateTo)) {
        result.push({ key: "期間開始", value: prev.priorConsultationStartDateFrom + '~' + prev.priorConsultationStartDateTo });
      }
      if (isSearchDetail && (prev.priorConsultationEndDateFrom || prev.priorConsultationEndDateTo)) {
        result.push({ key: "期間終了", value:  prev.priorConsultationEndDateFrom + '~' + prev.priorConsultationEndDateTo });
      }
      if (isSearchDetail && prev.isOveringAlert) result.push({ key: "警告基準値を超過", value: prev.isOveringAlert });
      return result;
    },
    updateGeneratorCollectList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectGeneratorCollectList = this.generatorCollectList
        return
      }
      this.selectGeneratorCollectList = this.generatorCollectList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorDisposalList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectGeneratorDisposalList = this.generatorDisposalList
        return
      }
      this.selectGeneratorDisposalList = this.generatorDisposalList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
  },
  watch: {
    selectedGeneratorCollect: function(newVal) {
      this.searchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedGeneratorDisposal: function(newVal) {
      this.searchParam.disposalCompanyId = newVal ? newVal.id : ''
    }
  },

  mounted() {
    this.searchParam = this.$store.getters["searchParamPrior/get"];
    this.isSearchDetail = this.$store.getters["searchParamPrior/isSearchDetail"];

    window.addEventListener("scroll", this.handleScroll);

    this.getGeneratorApi();
    this.getJwnetItemsApi();
    this.getGeneratorCollectApi();
    this.getGeneratorDisposalApi();

  },
};
</script>
