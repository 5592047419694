import axios from "axios";
import { API_URL } from "@/const";

export default {
  data() {
    return {
      selectGeneratorCollectList: [], //入力テキストで絞る排出事業者選択肢
      selectGeneratorDisposalList: [], //入力テキストで絞る排出事業者選択肢
      generateCompanyList: [],
      jwnetItemType: [],
      generatorCollectList: [],
      generatorDisposalList: [],
    };
  },
  methods: {
    // 排出事業者選択肢取得API
    getGeneratorApi() {
      return axios
          .get(API_URL.ENTRUST.GENERATE_SELECT_COMPANY)
          .then((res) => {
            this.generateCompanyList = res.data.generateCompanyList;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    //廃棄物種類選択肢を取得
    getJwnetItemsApi() {
      return axios
          //  CBAの場合：廃棄物種類選択肢取得を呼び出して、
          //  CBA以外場合：委託契約に紐づく廃棄物種類選択肢取得API
          .get(this.$store.getters["userInfo/isCba"] ? API_URL.DOMAIN.JWNET_ITEM_TYPE : API_URL.ENTRUST.JWNET_ITEM_TYPE)
          .then((res) => {
            this.jwnetItemType = res.data.jwnetItems;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 運搬事業者選択肢取得API
    getGeneratorCollectApi() {
      const isAuthority = this.$store.getters["userInfo/isGenerator"] || this.$store.getters["userInfo/isCba"]
      if (isAuthority) {
        this.getGeneratorCollectSelection(API_URL.MANIFEST.GENERATOR_COLLECT_LIST).then((data) => {
          this.generatorCollectList = data;
          this.selectGeneratorCollectList = data;
          if (this.searchParam.collectCompanyId) {
            this.selectedGeneratorCollect = this.generatorCollectList.find(e => e.id === this.searchParam.collectCompanyId)
          }
        }).catch(() => {})
      }
    },

    // 処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      const isAuthority = this.$store.getters["userInfo/isGenerator"] || this.$store.getters["userInfo/isCba"]
      if (isAuthority) {
        this.getGeneratorDisposalSelection().then((data) => {
          this.generatorDisposalList = data;
          this.selectGeneratorDisposalList = data;
          if (this.searchParam.disposalCompanyId) {
            this.selectedGeneratorDisposal = this.generatorDisposalList.find(e => e.id === this.searchParam.disposalCompanyId)
          } 
        }).catch(() => {})
      }
    },
  },
};
