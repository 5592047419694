<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="事前協議書一覧" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />

      <!-- 検索フォーム ============================================================ -->
      <SearchForm
          @search="getPriorConsultationSearchApi"
      />

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />

        <div class="c-infoList__wrap">
          <div class="c-infoList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell md">
                <a @click="clickSortChangeHandler">
                <span
                    class="c-infoList__cell__sort"
                    :class="{
                    asc: sortParams.type.toLowerCase() === 'asc',
                    desc: sortParams.type.toLowerCase() === 'desc',
                  }"
                >協議書管理番号</span>
                </a>
              </div>
              <div class="c-infoList__cell sm">排出事業者名</div>
              <div class="c-infoList__cell sm">廃棄物種類</div>
              <div class="c-infoList__cell md">ルート</div>
              <div class="c-infoList__cell md">排出事業場名</div>
              <div class="c-infoList__cell md">運搬事業者名</div>
              <div class="c-infoList__cell md">処分事業者名</div>
              <div class="c-infoList__cell sm">上限</div>
              <div class="c-infoList__cell sm">協議書期間</div>
              <div class="c-infoList__cell sm">登録日</div>
            </div>

            <!-- 検索結果あり -->
            <template v-if="priorList.length">
              <div
                  v-for="(prior, priorIndex) of priorList"
                  :key="'prior-' + priorIndex"
                  class="c-infoList__row list"
              >
                <div class="c-infoList__cell c-tooltip md"
                     @click="toDetail(prior.priorConsultationId)">
                  <router-link :to="`/priorconsultation/${prior.priorConsultationId}`">
                    {{ prior.priorConsultationNo && prior.priorConsultationNo.length > 30 ? (prior.priorConsultationNo.slice(0, 30) + '...') : prior.priorConsultationNo }}
                  </router-link>
                  <div v-if="checkIsLimit(prior)" class="c-status info-red">
                    <div class="c-infoList__popup">
                      <p>上限値を超過しています。</p>
                    </div>
                  </div>
                  <div v-else-if="checkIsAlert(prior)" class="c-status info-yellow">
                    <div class="c-infoList__popup">
                      <p>警告基準値になっています。</p>
                    </div>
                  </div>
                </div>
                <div class="c-infoList__cell sm">
                  <div class="c-text horizontal_dot">{{ prior.generateCompanyInfo.name }}</div>
                </div>
                <div class="c-infoList__cell sm">
                <div class="c-text horizontal_dot">{{ prior.jwnetItemTypeInfo.name }}</div>
                </div>
                <div class="c-infoList__cell md">
                  <CommaText :items="prior.routeList" arg="name" />
                </div>
                <div class="c-infoList__cell md">
                  <CommaText :items="prior.generateStoreInfo" arg="name" />
                </div>
                <div class="c-infoList__cell md">
                  <CommaText :items="prior.collectCompanyList" arg="name" />
                </div>
                <div class="c-infoList__cell md">
                  <CommaText :items="prior.disposalCompanyList" arg="name" />
                </div>
                <div class="c-infoList__cell sm">
                  {{ prior.limitQuantity }}
                  {{ prior.wasteUnitType.name }}
                </div>
                <div class="c-infoList__cell sm">
                  {{ prior.priorConsultationStartDate | dateFormat}}<br>
                  ~{{ prior.priorConsultationEndDate | dateFormat}}
                </div>
                <div class="c-infoList__cell sm">
                  {{ prior.createDate | dateFormat }}
                </div>
              </div>
            </template>

            <!-- 検索結果なし -->
            <template v-else>
              <div class="c-infoList__row list">
                <div class="c-infoList__cell">
                  検索条件に一致する事前協議書はありません。
                </div>
              </div>
            </template>
          </div>
        </div>

        <Pagination v-if="priorList.length"
                    :params="paginationParams"
                    :page.sync="paginationParams.page"
                    @callback="getPriorConsultationSearchApi(searchedParam, true)"
        />
      </div>
    </main>

    <AppFooter />
  </div>
</template>

<script>
import SearchForm from "./components/SearchForm.vue";
import search from "./mixins/search";
import Pagination from "@/components/parts/Pagination";
import CommaText from "@/components/parts/CommaText";
import ToastMessage from "@/components/parts/ToastMessage";
import common from "@/mixin/common";

export default {
  name: "priorConsultation-all",
  components: {
    ToastMessage,
    SearchForm,
    Pagination,
    CommaText,
  },
  mixins: [search, common],
  data() {
    return {
      priorList: [],
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        column: "prior_consultation_no",
        type: "asc",
      },
      errMsgs: [],
      toastMsg: "",
    };
  },

  computed: {
  },

  methods: {
    // 許可証番号ソート変更
    clickSortChangeHandler() {
      this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      this.getPriorConsultationSearchApi(this.searchedParam,false);
    },

    //上限値超過かチェック
    checkIsLimit(prior) {
      return prior.isLimit;
    },
    //警告基準値かチェック
    checkIsAlert(prior) {
      return prior.isAlert;
    },

    toDetail(priorId) {
      const transitionSource = location.pathname;
      this.$router.push(`/priorconsultation/${priorId}?transitionSource=${transitionSource}`);
    },
  },

  beforeCreate() {
  },

  mounted() {
    this.getPriorConsultationSearchApi(this.$store.getters["searchParamPrior/get"], false);
  },
};
</script>
