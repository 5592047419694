import axios from "axios";
import { API_URL } from "@/const";

export default {
  methods: {
    // URL生成
    getSearchApiUrl() {
      const params = new URLSearchParams();
      params.append("page", this.paginationParams.page);
      params.append("pageSize", this.paginationParams.pageSize);
      params.append("sort", `${this.sortParams.column}:${this.sortParams.type}`);

      if (this.searchedParam.priorConsultationNo) params.append("priorConsultationNo", this.searchedParam.priorConsultationNo);
      if (this.searchedParam.targetMunicipalityName) params.append("targetMunicipalityName", this.searchedParam.targetMunicipalityName);
      if (this.searchedParam.generateCompanyId) params.append("generateCompanyId", this.searchedParam.generateCompanyId);
      if (this.searchedParam.jwnetItemTypeId) params.append("jwnetItemTypeId", this.searchedParam.jwnetItemTypeId);
      if (this.searchedParam.routeName) params.append("routeName", this.searchedParam.routeName);
      if (this.searchedParam.collectCompanyId) params.append("collectCompanyId", this.searchedParam.collectCompanyId);
      if (this.searchedParam.disposalCompanyId) params.append("disposalCompanyId", this.searchedParam.disposalCompanyId);
      if (this.searchedParam.priorConsultationStartDateFrom) params.append("priorConsultationStartDateFrom", this.searchedParam.priorConsultationStartDateFrom);
      if (this.searchedParam.priorConsultationStartDateTo) params.append("priorConsultationStartDateTo", this.searchedParam.priorConsultationStartDateTo);
      if (this.searchedParam.priorConsultationEndDateFrom) params.append("priorConsultationEndDateFrom", this.searchedParam.priorConsultationEndDateFrom);
      if (this.searchedParam.priorConsultationEndDateTo) params.append("priorConsultationEndDateTo", this.searchedParam.priorConsultationEndDateTo);
      if (this.searchedParam.isOveringAlert) params.append("isOveringAlert", this.searchedParam.isOveringAlert);
      return API_URL.PRIORCONSULTATION.SEARCH + '?' + params.toString();
    },

    // 事業者契約を取得
    getPriorConsultationSearchApi(searchParam, isPaging) {
      this.isAllChecked = false;
      if (!isPaging) {
        this.searchedParam = JSON.parse(JSON.stringify(searchParam)); // deep copy
        this.paginationParams.page = 1; // 1ページ目に初期化
      }
      axios
        .get(this.getSearchApiUrl())
        .then((res) => {
          this.priorList = res.data.priorConsultationList;
          this.paginationParams.total = res.data.searchParams.pagination.total;
          this.sortParams = res.data.searchParams.sorts[0];
          this.$store.commit("searchParamPrior/set", this.searchedParam);
        })
        .catch((err) => {
          this.priorList = [];
          console.log(err);
        });
    },
  },
};
